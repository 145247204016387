const newsListBlock = {
    newsList:           '.newslist-block',
    newsListList:       '.newslist-block__list',
    newsListLoadMore:   '.newslist-block__loadmore',
    init() {

        const _this = this;
        const $newsList = $(this.newsList);
            

        $newsList.each(function (index) {
            $(this).find(_this.newsListLoadMore).on("click", function (e) {
                _this.loadNews(_this, index);
            });

        });
    },
    loadNews(_this,index) {
        const listDom = $(_this.newsList).eq(index).find(_this.newsListList);
        const loadedNews = listDom.attr('data-loaded-news');
        const numberOfNews = listDom.attr('data-total-news');
        const lastToLoad = parseInt(loadedNews) + 2;
        const contentId = listDom.attr('data-content-id');

        if (parseInt(loadedNews) < parseInt(numberOfNews)) {
            _this.loadNewsXhrRequest(listDom, loadedNews, lastToLoad, numberOfNews, contentId, _this, index);
        } else {
            $(_this.newsList).eq(index).find(_this.newsListLoadMore).hide();
        }


    },
    loadNewsXhrRequest(listDom, loadedNews, lastToLoad, numberOfNews, contentId, _this, index) {
        const xhr = new XMLHttpRequest();

        xhr.open("GET", '/NewsListBlock/LoadNews/?loadedNews=' + loadedNews + '&contentReference=' + contentId);
        xhr.onload = function (e) {
            if (xhr.status === 200) {

                const response = xhr.responseText;
                const newLoadedNews = parseInt(loadedNews) + 1;
                if (response !== null && response !== undefined) {
                    listDom.append(response);
                    listDom.attr('data-loaded-news', newLoadedNews);
                    if (lastToLoad >= newLoadedNews && newLoadedNews < numberOfNews) {
                        _this.loadNewsXhrRequest(listDom, newLoadedNews, lastToLoad, numberOfNews, contentId, _this, index);
                    } 
                    if (newLoadedNews >= numberOfNews) {
                        $(_this.newsList).eq(index).find(_this.newsListLoadMore).hide();
                    }
                }
                return;
            }
        };
        xhr.send();

    }
};

export default newsListBlock;
newsListBlock.init();