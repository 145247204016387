﻿import jQuery from 'jquery';
global.$ = global.jQuery = require('jquery');

const searchPageFunc = {
    searchBtn:      '.searchmain .search__btn',
    searchField:    '.searchmain .search__field',
    pagination:     '.search-paging',
    init() {

        const _this         = this;
        const $searchfield  = $(this.searchField);
        const $searchbutton = $(this.searchBtn);

        if ($searchfield.length && $searchbutton.length) {
            if ($searchfield.val().length === 0)
                $searchbutton.attr('disabled', true);
        }

        $searchfield.keyup(function () {
            if ($(this).val().length != 0)
                $searchbutton.attr('disabled', false);
            else
                $searchbutton.attr('disabled', true);
        })

        _this.paginationFunc();
        $(window).resize(function() {
            _this.paginationFunc();
        });
  
    },
    paginationFunc() {
        var linksMargin           = parseInt(4);
        var paginationWidth       = $(this.pagination).outerWidth();
        var paginationLinkWidth  = $(this.pagination).find('.first-page').outerWidth() + linksMargin;
        var paginationLinksWidth  = 0;
        $(this.pagination).children().each(function() { 
            paginationLinksWidth += parseInt($(this).outerWidth() + linksMargin); 
        }); 

        if(paginationWidth > paginationLinksWidth){
            $(this.pagination).find('.page').removeClass('hide');
            $(this.pagination).find('.more-page').removeClass('show');
        }else {
            $(this.pagination).find('.more-page').addClass('show');

            var gap             = paginationLinksWidth - paginationWidth;
            var hideLinks       = Math.ceil(gap/paginationLinkWidth);
            var reverseIndex    = $(this.pagination).find('.more-page').index();
            var currentIndex    = $(this.pagination).find('.current-page').index();

            $(this.pagination).children().removeClass('hide');
            for (var i = hideLinks; i > 0 ; i--) {
                reverseIndex--;
                if(reverseIndex > 0 ){
                     if(currentIndex == reverseIndex){
                        reverseIndex--;
                    }
                    $(this.pagination).children().eq(reverseIndex).addClass('hide');
                    $(this.pagination).find('.first-page').removeClass('hide');
                }
            }
        }
    }
  };
  
export default searchPageFunc;
searchPageFunc.init();