﻿const videoBlock = {
    init() {

        $(".video-block__btn").each(function (index) {
            $(this).on("click", function (e) {
                e.preventDefault();
                $(this).parent().parent().addClass('hide');
                $(this).parent().parent().parent().find('.video-block__video').removeClass('hide');
            });
        });
        $(".video-block__closebtn").each(function (index) {
            $(this).on("click", function (e) {
                e.preventDefault();
                $(this).parent().parent().find('.video-block__video').addClass('hide');
                $(this).parent().parent().find('.video-block__image').removeClass('hide');
            });
        });

    }
};
export default videoBlock;
videoBlock.init();


