﻿const calculatorPage = {
    init() {

        //$('.calc-table table').tableHover({ colClass: 'hover' });
        $('.calc-table td').on('mouseover', function () {

            $(this).closest('tr').addClass('tablecell-highlight');
            $(this).closest('table').find('td:nth-child(' + ($(this).index() + 1) + ')').addClass('tablecell-highlight');
            //$(this).closest('table').find('td').addClass('tablecell-highlight');
        });
        $('.calc-table td').on('mouseout', function () {
            $(this).closest('tr').removeClass('tablecell-highlight');
            $(this).closest('table').find('td:nth-child(' + ($(this).index() + 1) + ')').removeClass('tablecell-highlight');
        });
    }
};
export default calculatorPage;
calculatorPage.init();
