import jQuery from 'jquery';
global.$ = global.jQuery = require('jquery');

const Footer = {
  footerSelect:   '.footertop__select ',
  footerBtn:      '.footercol__col .footercol__btn',
  eserviceBtn:    '.hygiennav__item--eservice',
  init() {

    const $footerSelect =       $(this.footerSelect);
    const $footerBtn =          $(this.footerBtn);
    const $eserviceBtn =        $(this.eserviceBtn);

    $footerSelect.on("change", function(e) {
      e.preventDefault();
      var value = $(this).val();
          if (value) {
            window.location.href = value;
          }
    });
    
    $footerBtn.on("touchend click", function(e) {
      e.preventDefault();

      $('html, body').animate({ scrollTop: 0 }, 2000, function() {
        if( !$eserviceBtn.hasClass('open') ) {
          $eserviceBtn.trigger('click'); 
        }
      }); 
    });

  }
};

export default Footer;
Footer.init();