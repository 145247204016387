const quickFind = {
    quickFind:                  '.quick-find',
    quickFindSticky:            'quick-find--sticky',
    quickFindHeading:           '.quick-find__heading',
    quickFindBtn:               '.quick-find__btn',
    quickFindList:              '.quick-find__list',
    quickFindHeadings:          '.page-left > .rte-content h2',
    quickFindScrollToTop:       '.quick-find__scroll-to-top',
    quickFindScrollToTopBtn:    '.quick-find__scroll-to-top-btn',
    init() {

        const $quickFind =                  $(this.quickFind);
        const quickFindSticky =             this.quickFindSticky;
        const $quickFindBtn =               $(this.quickFindBtn);
        const $quickFindList =              $(this.quickFindList);
        const $quickFindHeadings =          $(this.quickFindHeadings);
        const $quickFindHeading =           $(this.quickFindHeading);
        const $quickFindScrollToTop =       $(this.quickFindScrollToTop);
        const $quickFindScrollToTopBtn =    $(this.quickFindScrollToTopBtn);

        if( $quickFind.is(':visible') ){
            $quickFind.removeClass('quick-find--hide');
            if( $quickFindHeadings.not($quickFindHeading).length > 0) {
                $quickFindHeadings.not($quickFindHeading).each(function (index) {
                    $quickFindList.append('<li class="quick-find__listitem"><a class="quick-find__listlink" href="#" tabindex="-1">' + $(this).text() + '</a></li>')
                });
            }else {
                $quickFind.addClass('quick-find--hide');
            }
            $('.quick-find__listlink').on("touchend click", function(e) {
                e.preventDefault();
                $('html, body').animate({ 
                    scrollTop: ($quickFindHeadings.not($quickFindHeading).eq($(this).parent().index()).offset().top)-40
                }, 1000);
            });

            $quickFindBtn.on("click", function(e) {
                if( $quickFindList.hasClass('open') ) {
                    $quickFindList.removeClass('open');
                    $quickFindBtn.removeClass('open');
                    $quickFindList.find('a').attr('tabindex', '-1');
                    $quickFindBtn.focus();
                  }else {
                    $quickFindList.addClass('open');
                    $quickFindBtn.addClass('open');
                    $quickFindList.find('a').attr('tabindex', '0');
                    $quickFindList.find('a')[0].focus();
                  }
            });

            $quickFindScrollToTopBtn.on("click", function(e) {
                $('html, body').animate({ 
                    scrollTop: ($quickFind.offset().top)-20
                }, 1000);
            });

            $(window).scroll(function(){
                if($(window).scrollTop() > ($quickFind.offset().top)-20 ){
                    $quickFindScrollToTop.addClass('show');
                    $quickFind.addClass(quickFindSticky);
                }
                else{
                    $quickFindScrollToTop.removeClass('show');
                    $quickFind.removeClass(quickFindSticky);
                }
            });
        }
    }
};

export default quickFind;
quickFind.init();