﻿const calculatorBlock = {
    init() {

        //Calculation stuff
        $("input[type='range']").change(function (event) {
            var value = $(this).val();
            value = value.replace(',', '.');
            $(this).val(Math.floor(value));
            this.style.setProperty('--val', value);
            $(this).parent().parent().find('.textbox-container input').val($(this).val());
            calculate();
        });

        $(".slider-container input").mouseup(function (e, v) {
            var value = $(this).val();
            var tmp = $(this).parent().parent().find("input[type='number']");
            calculate();
        });

        $(".slider-container input").mousemove(function (e, v) {
            this.style.setProperty('--val', $(this).val());
            $(this).parent().parent().find('.textbox-container input').val($(this).val());
        });

        var isValid = false;
        $(".textbox-container input").on( "keyup mouseup", function(e) {
            validate( e, $(this), $(this).attr('min'), $(this).attr('max') );
            if( parseInt($(this).val()) >= parseInt($(this).attr('min')) ){
                if(isValid){
                    this.parentNode.parentNode.querySelectorAll('.slider-container input')[0].style.setProperty('--val', $(this).val());
                    $(this).parent().parent().find('.slider-container input').val($(this).val());
                    calculate();
                }
            }else if( parseInt($(this).val()) <= parseInt($(this).attr('min')) || $(this).val()  === ""){
                calculate();
            }
        });
        
        function validate(e, elem, minVal, maxVal) {
            isValid = e.target.checkValidity();
            elem.prev().addClass('hide');
            if (!isValid) {
                if ( elem.val() < parseInt(minVal)) {
                    elem.prev().html("Minst " + parseInt(minVal));
                    elem.prev().removeClass('hide');
                } else if ( elem.val() > maxVal) {
                    elem.prev().html("Max " + maxVal);
                    elem.prev().removeClass('hide');
                }
            }
        }

        function calculate() {
            var normal =        $('#normal').val();
            var normalMin =     $('#normal').attr('min');
            var normalMax =     $('#normal').attr('max');
            var available =     $('#available').val();
            var availableMin =  $('#available').attr('min');
            var availableMax =  $('#available').attr('max');
            var impediment =    $('#impediment').val();
            var impedimentMin = $('#impediment').attr('min');
            var impedimentMax = $('#impediment').attr('max');

            if(normal != ""){
                normal = Math.floor(normal.replace(",", "."));
            }
            if(available != ""){
                available = Math.floor(available.replace(",", "."));
            }
            if(impediment != ""){
                impediment = Math.floor(impediment.replace(",", "."));
            }

            $('#normal').val(normal);
            $('#available').val(available); 
            $('#impediment').val(impediment);

            $.ajax({ 
                url: '/CalculatorBlock/Calculate',
                data: {
                    normal:         normal,
                    normalMin:      normalMin,
                    normalMax:      normalMax,
                    available:      available,
                    availableMin:   availableMin,
                    availableMax:   availableMax,
                    impediment:     impediment,
                    impedimentMin:  impedimentMin,
                    impedimentMax:  impedimentMax,
                    tableUrl:       $('.calc-form').data('url')
                },
                success: function (response) {
                    $('.calc-result').html(response);
                }
            });
        }

        $('#calc .button').hide();
        
        $(document).ready(function() {
            if ($('.calculator-block').length > 0 ) {
                calculate();
            }
        });

    }
};
export default calculatorBlock;
calculatorBlock.init();


