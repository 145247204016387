import jQuery from 'jquery';
global.$ = global.jQuery = require('jquery');
import eService from '../../EService/Scripts/EService.js';

const mobileNavigation = {
  mobileNav:            '.mobilenav',
  mobileNavCont:        '.header__mobilenav',
  mobileNavBtn:         '.header__mobilenavbtn',
  eservice:             '.eservice',
  eserviceBtn:          '.hygiennav__item--eservice',
  mobileNavSearchField: '.mobilenav .search__field',
  mobileNavSearchBtn:   '.mobilenav .search__btn',
  mobileNavItem:        '.mobilenav__item ',
  mobileNavHeading:     '.mobilenav__btn',
  mobileNavClose:       '.mobilenav__close',
  init() {

    const $mobileNav =              $(this.mobileNav);
    const $mobileNavCont =          $(this.mobileNavCont);
    const $mobileNavBtn =           $(this.mobileNavBtn);
    const $eservice =               $(this.eservice);
    const $eserviceBtn =            $(this.eserviceBtn);
    const $mobileNavItem =          $(this.mobileNavItem);
    const $mobileNavHeading =       $(this.mobileNavHeading);
    const $mobileNavClose =         $(this.mobileNavClose);
    const _this =                   this;
    var keypress =                  this.keypress;

    $mobileNavBtn.on('keydown',function(evt){
      if (evt.which == 13) {
        keypress = true;
      }
    });

    $mobileNavBtn.on("click", function(e) {
      e.preventDefault();

      if( $mobileNav.attr('aria-hidden') == "true") {
        _this.openMobileNav(keypress);
      }else {
        _this.closeMobileNav();
      }

      if($eserviceBtn.hasClass('open')){
        eService.closeEService();
      }
      
    });
    var dragging = false;

    $mobileNavHeading.on("touchstart", function(){
      dragging = false;
    });
    $mobileNavHeading.on("touchmove", function(){
      dragging = true;
    });

    $mobileNavHeading.on("click", function(e) {
      e.preventDefault();
      if (dragging)
          return;

      if($(this).parent().parent().hasClass('mobilenav__item--open')){
        $(this).parent().parent().removeClass('mobilenav__item--open')
        $(this).parent().parent().find('ul a').attr('tabindex', '-1');
        $(this).focus();
      }else {
        $($mobileNavItem).removeClass('open');
        $(this).parent().parent().toggleClass('mobilenav__item--open');
        $(this).parent().parent().find('ul a').attr('tabindex', '0');
        $(this).parent().parent().find('ul a')[0].focus();
      }
    });
    
    $mobileNavClose.on("click", function(e) {
      e.preventDefault();
      $mobileNavBtn.trigger('click');
    });

    $mobileNav.on('keydown',function(evt){    // if tab or shift-tab pressed
      if (evt.which == 9) {
        _this.trapTabKey($(this), evt);
      }else if(evt.which == 27){
        _this.trapEscapeKey($(this), evt);
      }else if(evt.which == 13 && $(evt.target).prop("class") == 'mobilenav__btn'){
        $(evt.target).trigger('click');
      }
    });

  },
  openMobileNav(keypress) {
    var $thisMobileNavCont        = $(this.mobileNavCont);
    var $thisMobileNav            = $(this.mobileNav);       
    var $thisMobileNavHeading     = $(this.mobileNavHeading);           
    var $thisMobileNavSearchField = $(this.mobileNavSearchField);
    var $thisMobileNavSearchBtn   = $(this.mobileNavSearchBtn);
    var $thisMobileNavClose       = $(this.mobileNavClose);

    $('body').addClass('mobilenav-open');
    $thisMobileNavCont.addClass('open');
    $thisMobileNav.addClass('open');
    $thisMobileNav.attr('aria-hidden', 'false');
    $thisMobileNavHeading.attr('tabindex', '0');
    $thisMobileNavSearchField.attr('tabindex', '0');
    $thisMobileNavSearchBtn.attr('tabindex', '0');
    $thisMobileNavClose.attr('tabindex', '0');
    setTimeout(function(){
      if(keypress){
        keypress = false;
        $thisMobileNavClose.focus();
      }else {
        if( $(':focus').parent('.hygiennav__item--search').length){
          $thisMobileNavSearchField.focus();
        }else {
          $('.mobilenav__link').eq(0).focus();
        }
      }
    }, 500);

  },
  closeMobileNav() {

    $('body').removeClass('mobilenav-open');
    $(this.mobileNavCont).removeClass('open');
    $(this.mobileNav).removeClass('open');
    $(this.mobileNav).attr('aria-hidden', 'true');
    $(this.mobileNavHeading).attr('tabindex', '-1');
    $(this.mobileNavSearchField).attr('tabindex', '-1');
    $(this.mobileNavSearchBtn).attr('tabindex', '-1');
    $(this.mobileNavClose).attr('tabindex', '-1');
    $(this.mobileNavBtn).focus();

  },
  trapTabKey(obj, evt) {
    
    var focusableElementsString = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]';

        // get list of all children elements in given object
        var o = obj.find('*');
        // get list of focusable items
        var focusableItems;
        focusableItems = o.filter(focusableElementsString).filter(':visible')
        
        // get currently focused item
        var focusedItem;
        focusedItem = jQuery(':focus');

        // get the number of focusable items
        var numberOfFocusableItems;
        numberOfFocusableItems = focusableItems.length;

        // get the index of the currently focused item
        var focusedItemIndex;
        focusedItemIndex = focusableItems.index(focusedItem);

        if (evt.shiftKey) {
            //back tab
            // if focused on first item and user preses back-tab, go to the last focusable item
            if (focusedItemIndex == 0) {
                focusableItems.get(numberOfFocusableItems - 1).focus();
                evt.preventDefault();
            }

        } else {
            //forward tab
            // if focused on the last item and user preses tab, go to the first focusable item
            if (focusedItemIndex == numberOfFocusableItems - 1) {
                focusableItems.get(0).focus();
                evt.preventDefault();
            }
        }
  },
  trapEscapeKey(obj, evt) {
    
    // if escape pressed

      // get list of all children elements in given object
      var o = obj.find('*');

      // get list of focusable items
      var cancelElement;
      cancelElement = o.filter(this.eserviceClose);
      // close the modal window
      cancelElement.trigger('click');
      evt.preventDefault();

  }
};

export default mobileNavigation;
mobileNavigation.init();