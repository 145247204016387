﻿const CompactContentBlock = {
    CompactContentBlock:           '.compact-content',
    CompactContentBlockHeader:   '.compact-content__button',
    CompactContentBlockText:     '.compact-content__text',
    init() {
        var self = this;
        const $CompactContentBlock =           $(this.CompactContentBlock);
        const $CompactContentBlockHeader =   $(this.CompactContentBlockHeader);

        $CompactContentBlockHeader.click(function () {
            $(this).toggleClass("compact-content__button--open");
            $(this).parent().next(self.CompactContentBlockText).slideToggle();

            window.observer.fire("compact-content-open");
        });
    }
};

export default CompactContentBlock;
CompactContentBlock.init();
