import jQuery from 'jquery';
global.$ = global.jQuery = require('jquery');

const Header = {
  mainNavBtn:           '.header__mainnavbtn',
  mobileNavBtn:         '.header__mobilenavbtn',
  searchBtn:            '.hygiennav__item--search',
  mobileNavSearchField: '.mobilenav .search__field',
  init() {

    const $mainNavBtn =   $(this.mainNavBtn);
    const $mobileNavBtn = $(this.mobileNavBtn);
    const $searchBtn =    $(this.searchBtn);
    const _this =         this;
    
    /*document.addEventListener('keyup', function(e) {
      9 != e.keyCode || e.metaKey || e.ctrlKey || console.log(document.activeElement)
    }, false)*/

    //TODO: make sure clicking closes other open menus in a nicer way
    
    $searchBtn.on("touchend click", function(e) {
      e.preventDefault();

      if( $mainNavBtn.is(":visible") ) {
        $mainNavBtn.trigger('click');
      }else {
        $mobileNavBtn.trigger('click');
      }

    });

  }
};

export default Header;
Header.init();