import jQuery from 'jquery';
global.$ = global.jQuery = require('jquery');
import eService from '../../EService/Scripts/EService.js';

const mainNavigation = {
  
  mainNav:              '.mainnav',
  mainNavCont:          '.header__mainnav',
  mainNavBtn:           '.header__mainnavbtn',
  mainNavClose:         '.mainnav__close',
  eservice:             '.eservice',
  eserviceBtn:          '.hygiennav__item--eservice',
  searchBtn:            '.hygiennav__item--search',
  mainNavSearchField:   '.mainnav .search__field',
  mainNavSearchBtn:     '.mainnav .search__btn',
  bodyMainNavOpen:      'body.mainnav-open',
  mainNavOpen:          'mainnav-open',
  keypress:                 false,
  init() {

    const $mainNav =              $(this.mainNav);
    const $mainNavCont =          $(this.mainNavCont);
    const $mainNavBtn =           $(this.mainNavBtn);
    const $mainNavClose =         $(this.mainNavClose);
    const $eservice =             $(this.eservice);
    const $eserviceBtn =          $(this.eserviceBtn);
    const $mainNavSearchField =   $(this.mainNavSearchField);
    const $mainNavSearchBtn =     $(this.mainNavSearchBtn);
    const mainNavOpen =           this.mainNavOpen;
    const _this =                 this;
    var keypress = this.keypress;

    $mainNavBtn.on('keydown',function(evt){
      if (evt.which == 13) {
        keypress = true;
      }
    });

    //TODO: make sure clicking closes other open menus in a nicer way

    $mainNavBtn.on("click", function(e) {
      e.preventDefault();

      if( $mainNav.attr('aria-hidden') == "true") {
        _this.openMainNav(keypress);
      }else {
        _this.closeMainNav();
      }
      
      if($eserviceBtn.hasClass('open')){
        eService.closeEService();
      }

    });
    
    $mainNavClose.on("click", function(e) {
      e.preventDefault();
      $mainNavBtn.trigger('click');
    });

    $mainNav.on('keydown',function(evt){    // if tab or shift-tab pressed
      if (evt.which == 9) {
        _this.trapTabKey($(this), evt);
      }else if(evt.which == 27){
        _this.trapEscapeKey($(this), evt);
      }
    });

  },
  openMainNav(keypress) {
    var $thisMainNavCont        = $(this.mainNavCont);
    var $thisMainNav            = $(this.mainNav);           
    var $thisMainNavSearchField = $(this.mainNavSearchField);
    var $thisMainNavSearchBtn   = $(this.mainNavSearchBtn);
    var $thisMainNavClose       = $(this.mainNavClose);

    $('body').addClass(this.mainNavOpen);
    $thisMainNavCont.addClass('open');
    $thisMainNav.addClass('open')
    $thisMainNav.attr('aria-hidden', 'false');
    $thisMainNav.find('a').attr('tabindex', '0');
    $thisMainNavSearchField.attr('tabindex', '0');
    $thisMainNavSearchBtn.attr('tabindex', '0');
    $thisMainNavClose.attr('tabindex', '0');
    setTimeout(function(){
      if(keypress){
        keypress = false;
        $thisMainNavClose.focus();
      }else {
        if( $(':focus').parent('.hygiennav__item--search').length){
          $thisMainNavSearchField.focus();
        }else {
          $('.mainnav__list a').eq(0).focus();
        }
      }
    }, 500);
    
    var $mainNavBtn = $(this.mainNavBtn);
    $('html').on("click.outsideclick", $(this.bodyMainNavOpen), function(e) {
      var container = $('.header-container');
      
      if($(e.target).closest(container).length != 1){
        
        $mainNavBtn.trigger('click');
      }
    });

  },
  closeMainNav() {
    $('html').off(".outsideclick");
    $('body').removeClass(this.mainNavOpen);
    $(this.mainNavCont).removeClass('open');
    $(this.mainNav).removeClass('open')
    $(this.mainNav).attr('aria-hidden', 'true');
    $(this.mainNav).find('a').attr('tabindex', '-1');
    $(this.mainNavSearchField).attr('tabindex', '-1');
    $(this.mainNavSearchBtn).attr('tabindex', '-1');
    $(this.mainNavClose).attr('tabindex', '-1');
    $(this.mainNavBtn).focus();

  },
  trapTabKey(obj, evt) {
    
    var focusableElementsString = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]';

        // get list of all children elements in given object
        var o = obj.find('*');
        // get list of focusable items
        var focusableItems;
        focusableItems = o.filter(focusableElementsString).filter(':visible')

        // get currently focused item
        var focusedItem;
        focusedItem = jQuery(':focus');

        // get the number of focusable items
        var numberOfFocusableItems;
        numberOfFocusableItems = focusableItems.length

        // get the index of the currently focused item
        var focusedItemIndex;
        focusedItemIndex = focusableItems.index(focusedItem);
        if (evt.shiftKey) {
            //back tab
            // if focused on first item and user preses back-tab, go to the last focusable item
            if (focusedItemIndex == 0) {
                focusableItems.get(numberOfFocusableItems - 1).focus();
                evt.preventDefault();
            }

        } else {
            //forward tab
            // if focused on the last item and user preses tab, go to the first focusable item
            if (focusedItemIndex == numberOfFocusableItems - 1) {
                focusableItems.get(0).focus();
                evt.preventDefault();
            }
        }
  },
  trapEscapeKey(obj, evt) {
    
    // if escape pressed

      // get list of all children elements in given object
      var o = obj.find('*');

      // get list of focusable items
      var cancelElement;
      cancelElement = o.filter(this.eserviceClose);
      // close the modal window
      cancelElement.trigger('click');
      evt.preventDefault();

  }
};

export default mainNavigation;
mainNavigation.init();