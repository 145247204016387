const memberApplicationBlock = {
    memberApplicationBlockHeading:          '.memberapplication-block__heading',
    memberApplicationBlockText:             '.memberapplication-block__text',
    memberApplicationBlockImg:              '.memberapplicationcontainer-block__image img',
    memberApplicationBlockData:             'memberapplication-block--data',
    memberApplicationBlockHeadingActive:    'memberapplication-block__heading--active',
    memberApplicationBlockTextActive:       'memberapplication-block__text--active',
    init() {
        var self                                    = this;
        const $memberApplicationBlockHeading        = $(this.memberApplicationBlockHeading);
        const $memberApplicationBlockText           = $(this.memberApplicationBlockText);
        const $memberApplicationBlockImg            = $(this.memberApplicationBlockImg);
        const memberApplicationBlockImgLink         = $(this.memberApplicationBlockImg).attr('src');
        const memberApplicationBlockData            = this.memberApplicationBlockData;
        const memberApplicationBlockHeadingActive   = this.memberApplicationBlockHeadingActive;
        const memberApplicationBlockTextActive      = this.memberApplicationBlockTextActive;

        $memberApplicationBlockHeading.on("click", function(e) {
            
            if( $(this).next(self.memberApplicationBlockText).hasClass(memberApplicationBlockTextActive) ){
                $(this).removeClass(memberApplicationBlockHeadingActive);
                $(this).next().removeClass(memberApplicationBlockTextActive);
                if( !$(this).hasClass(memberApplicationBlockData) ){
                    $memberApplicationBlockImg.attr("src", memberApplicationBlockImgLink);
                }
            }else {
                $(this).next(self.memberApplicationBlockText).addClass(memberApplicationBlockTextActive);
                $(this).addClass(memberApplicationBlockHeadingActive);
                if( !$(this).hasClass(memberApplicationBlockData) ){
                    if(  $(this).parent().attr("data-img").length >0 ){
                    $memberApplicationBlockImg.attr("src", $(this).parent().attr("data-img"));
                    }else {
                        $memberApplicationBlockImg.attr("src", memberApplicationBlockImgLink);
                    }
                }
            }

        });
    }
};

export default memberApplicationBlock;
memberApplicationBlock.init();