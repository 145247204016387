﻿const faqBlock = {
    faqBlock:               '.faq-block',
    faqBlockQuestion:       '.faq-block__question',
    faqBlockAnswer:         '.faq-block__answer',
    faqCategoryBlockOpen:   '.faq-category-block--open',
    faqBlockQuestionActive: 'faq-block__question--active',
    faqBlockAnswerActive:   'faq-block__answer--active',
    init() {
        var self                        = this;
        const $faqBlock                 = $(this.faqBlock);
        const $faqBlockQuestion         = $(this.faqBlockQuestion);
        const $faqBlockAnswer           = $(this.faqBlockAnswer);
        const $faqCategoryBlockOpen     = $(this.faqCategoryBlockOpen);
        const faqBlockQuestionActive    = this.faqBlockQuestionActive;
        const faqBlockAnswerActive      = this.faqBlockAnswerActive;
        const updateQuery = function updateQueryStringParameter(uri, key, value) {
            var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
            var separator = uri.indexOf('?') !== -1 ? "&" : "?";
            if (uri.match(re)) {
                return uri.replace(re, '$1' + key + "=" + value + '$2');
            }
            else {
                return uri + separator + key + "=" + value;
            }
        }
        $faqBlockQuestion.on("click", function(e) {
            if ($(this).next(self.faqBlockAnswer).hasClass(faqBlockAnswerActive)) {
                $faqBlockQuestion.removeClass(faqBlockQuestionActive);
                $faqBlockAnswer.removeClass(faqBlockAnswerActive);
            } else {
                $faqBlockQuestion.removeClass(faqBlockQuestionActive);
                $faqBlockAnswer.removeClass(faqBlockAnswerActive);
                $(this).addClass(faqBlockQuestionActive);
                $(this).next(self.faqBlockAnswer).addClass(faqBlockAnswerActive);

                let newSectionId = document.getElementsByClassName('faq-archive__navitem--open');
                let newCategoryId = document.getElementsByClassName('faq-category-block__categoriesitem--active');
     
                if (newSectionId.length > 0 && newCategoryId.length > 0) {
                    var uri = window.location.href.split('?')[0];
                    uri = updateQuery(uri, 'sectionId', newSectionId[0].getElementsByTagName('button')[0].dataset.id);
                    uri = updateQuery(uri, 'categoryId', newCategoryId[0].dataset.category);
                    uri = updateQuery(uri, 'questionId', this.getElementsByTagName('button')[0].dataset.question);
                    if (window.history.pushState) {
                        window.history.pushState({ path: uri }, '', uri);
                    }
                }
            }
        });
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });
        let value = params.questionId;
        let firstItem = document.querySelectorAll('[data-question="' + value + '"]');
        if (firstItem.length > 0) {
            let firstOpen = $(firstItem[0]);
            firstOpen.trigger('click');
        }
    }
};

export default faqBlock;
faqBlock.init();