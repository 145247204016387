﻿const faqArchive = {
    item:               '.faq-category-block__categoriesitem',
    activeInfo:         '.faq-category-block.faq-category-block--open',
    mobileBtn:          '.faq-category-block__mobilebutton',
    questions:          '.faq-category-block__questions',
    faqblock:           '.faq-block',
    faqblockActive:     'faq-block--active',
    itemActive:         'faq-category-block__categoriesitem--active',
    mobileBtnActive:    'faq-category-block__mobilebutton--active',
    questionsActive:    'faq-category-block__questions--active',
    init() {
        var self                = this;
        var triggerMobile       = false;
        var triggeItem          = false;
        const $item             = $(this.item);
        const $mobileBtn        = $(this.mobileBtn);
        const $questions        = $(this.questions);
        const itemActive        = this.itemActive;
        const faqblockActive    = this.faqblockActive;
        const mobileBtnActive   = this.mobileBtnActive;
        const questionsActive   = this.questionsActive;
        const updateQuery       = function updateQueryStringParameter(uri, key, value) {
            var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
            var separator = uri.indexOf('?') !== -1 ? "&" : "?";
            if (uri.match(re)) {
                return uri.replace(re, '$1' + key + "=" + value + '$2');
            }
            else {
                return uri + separator + key + "=" + value;
            }
        }

        $mobileBtn.on("click", function(e) {
            
            if( $(this).hasClass(mobileBtnActive) ){
                $(this).removeClass(mobileBtnActive);
                $(this).next().removeClass(questionsActive);
            }else {
                $mobileBtn.removeClass(mobileBtnActive);
                $questions.removeClass(questionsActive);
                $(this).addClass(mobileBtnActive);
                $(this).next().addClass(questionsActive);
            }
            
            
            if (e.originalEvent !== undefined) {
                $mobileBtn.each(function(i) {
                    if( $(this).hasClass(mobileBtnActive) ){
                        $item.eq(i).trigger("click");
                    }
                });
                if( $(this).hasClass(mobileBtnActive) ){
                    $('html, body').animate({ 
                        scrollTop: ($(this).offset().top)-40
                    }, 200);
                }
            }
        });
        $mobileBtn.eq(0).trigger("click");

        $item.on("click", function(e, firstLoad) {
            var _this = $(this);
            var category = $(this).data("category").toString();

            $item.removeClass(itemActive);
            $(this).addClass(itemActive);

            $(self.activeInfo + " " +self.faqblock).each(function () {
                var value = $(this).data("category") ||"";
                var name = $(this).data("name") ||"";
                if (value.toString().indexOf(category) > -1){
                    if (name ==  _this.text() ){
                        $(this).addClass(faqblockActive);
                    }else {
                        $(this).removeClass(faqblockActive);
                    }
                }else {
                    $(this).removeClass(faqblockActive);
                }
            });  

            if (e.originalEvent !== undefined) {
                $item.each(function(i) {
                    if( $(this).hasClass(itemActive) ){
                        $mobileBtn.eq(i).trigger("click");
                    }
                });
            }

            let newSectionId = document.getElementsByClassName('faq-archive__navitem--open');
            let newCategoryId = document.getElementsByClassName('faq-category-block__categoriesitem--active');
            if (newSectionId.length > 0 && newCategoryId.length > 0 && firstLoad !== 'true') {
                var uri = window.location.href.split('?')[0];
                uri = updateQuery(uri, 'sectionId', newSectionId[0].getElementsByTagName('button')[0].dataset.id);
                uri = updateQuery(uri, 'categoryId', newCategoryId[0].dataset.category);
                if (window.history.pushState) {
                    window.history.pushState({ path: uri }, '', uri);
                }
            }
        });

        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });
        let value = params.categoryId;
        let firstItem = document.querySelectorAll('[data-category="' + value + '"]');   
        if (firstItem.length > 0) {
            let firstOpen = $(firstItem[0]);
            firstOpen.trigger('click', ['true']);
        } else {
            $item.eq(0).trigger('click', ['true']);
        }
    }
};

export default faqArchive;
faqArchive.init();  