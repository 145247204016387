﻿const $contactPageLink = $('.contact-form-link');
const $contactForm = $('.contact-form');

if ($contactPageLink.length || $contactForm.length) {
    
    const activateValidation = function () {
        const form = '#Contact';
        const name = '#Name';
        const email = '#Email';
        const toEmail = '#ToEmail';
        const phonenumber = '#Phonenumber';
        const message = '#Message';
	      const accept = '#Accept';
	      const recaptcha = '#Recaptcha';
        const send = '#ContactSend';
        const validClass = 'valid';
        const invalidClass = 'invalid';
        const formData = $(form).serializeArray();
        const displayErrorMessage = function (element) {
          const valid = $(element).hasClass(validClass);
          
            var errorElement = $(element).next('span');
            if ($(element).attr('id') === accept.replace('#', '')) {
                errorElement = $(element).next().next().next().next('span');
            }
            if (!valid) {
                var errorMessage;
                if ($(element).attr('id') === email.replace('#', '')) {
                    if ($(element).val()) {
                        errorMessage = $(element).attr('data-val-email');
                    }
                    else {
                        errorMessage = $(element).attr('data-val-required');
                    }
                }
                else if ($(element).attr('id') === phonenumber.replace('#', '')) {
                    errorMessage = $(element).attr('data-val-regex');
                }
                else {
                    errorMessage = $(element).attr('data-val-required');
                }
                errorElement.empty();
                errorElement.text(errorMessage);
                errorElement.removeClass('field-validation-valid').addClass('field-validation-error');
            }
            else {
                errorElement.removeClass('field-validation-error').addClass('field-validation-valid');
                errorElement.empty();
            }
        }

        for (var input in formData) {
            const element = $('#' + formData[input]['name']);
            const invalid = element.hasClass('input-validation-error')
                || (element.val() === '' && element.attr('id') !== phonenumber.replace('#', '')
                || (!element.is(':checked') && element.attr('id') === accept.replace('#', '')));
            if (invalid) {
                element.addClass(invalidClass);
            }
            else {
                element.addClass(validClass);
            }
        }

        $(name + ',' + email + ',' + message).on('input', function () {
            const input = $(this);
            const isName = input.val();
            if (isName) {
                input.removeClass(invalidClass).addClass(validClass);
            }
            else {
                input.removeClass(validClass).addClass(invalidClass);
            }
        });

        $(email).on('input', function () {
            const input = $(this);
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            const isEmail = re.test(input.val());
            if (isEmail) {
                input.removeClass(invalidClass).addClass(validClass);
            }
            else {
                input.removeClass(validClass).addClass(invalidClass);
            }
        });

        $(phonenumber).on('input', function () {
            const input = $(this);
            if ((!isNaN(parseFloat(input.val())) && isFinite(input.val())) || input.val() == '') {
                input.removeClass(invalidClass).addClass(validClass);
            }
            else {
                input.removeClass(validClass).addClass(invalidClass);
            }
        });

        $(accept).click(function () {
            const input = $(this);
            if ($(this).is(':checked')) {
                input.removeClass(invalidClass).addClass(validClass);
                displayErrorMessage(this);
            }
            else {
                input.removeClass(validClass).addClass(invalidClass);
                displayErrorMessage(this);
            }
        });

        $(name + ',' + email + ',' + message + ',' + phonenumber).on('change', function () {
            displayErrorMessage(this);
        });

        $(name + ',' + email + ',' + message + ',' + phonenumber + ',' + accept).on('change', function () {
            var error_free = true;
            for (var input in formData) {
                const element = $('#' + formData[input]['name']);
              var valid = element.hasClass(validClass) || formData[input]['name'] == "__RequestVerificationToken";
                if (!valid) {
                    error_free = false;
                }
            }
            if (error_free) {
                $(send).css('cursor', 'pointer');
            } else {
                $(send).css('cursor', 'auto');
            }
        });

        $(send).on('click', function (e) {
            e.preventDefault();
            var error_free = true;
            for (var input in formData) {
                const element = $('#' + formData[input]['name']);
                displayErrorMessage(element);
              const valid = element.hasClass(validClass) || formData[input]['name'] == "__RequestVerificationToken";
                if (!valid) {
                    error_free = false;
                }
            }
          if (error_free) {
            $('.loading-overlay').removeClass('hidden');

            grecaptcha.execute(window.sitekey, { action: 'ContactFormPage' }).then(function(token) {
              

		          if (token) {
			          const form = {
				          Name: $(name).val(),
				          Email: $(email).val(),
				          Phonenumber: $(phonenumber).val(),
				          Message: $(message).val(),
				          Accept: $(accept).val(),
				          ToEmail: $(toEmail).val(),
				          Recaptcha: token,
				          __RequestVerificationToken: $("input[name='__RequestVerificationToken'").val()
		          };
			          const xhr = new XMLHttpRequest();
                      xhr.open('POST', '/ContactFormPage/SendForm');
                      xhr.setRequestHeader('__RequestVerificationToken', form.__RequestVerificationToken);
			          xhr.setRequestHeader("Content-type", "application/json");
			          xhr.onload = function(e) {
				          if (xhr.status === 200) {
					          const response = xhr.responseText;
					          if (response !== null && response !== undefined) {
						          if ($('.contact-form-container').length) {
							          $('.contact-form-container')[0].innerHTML = response;
						          }
						          return;
					          }
				          };
			          }
			          xhr.send(JSON.stringify(form));
		          }
	          });
                
                
            }
        });
    };

    if($contactForm.length) {
        activateValidation();
    }

    if ($contactPageLink.length && $contactPageLink.prop("tagName") !== 'button') {
        for (var i = 0; $contactPageLink.length > i; i++) {
            const urlValue = $($contactPageLink[i]).attr('href');
            const classes = $($contactPageLink[i]).attr('class');
            $($contactPageLink[i]).replaceWith($('<button data-url="' + urlValue + '" class="' + classes + '">' + $($contactPageLink[i]).text() + '</button>'));
        }
        $('.contact-form-link').on('click', function () {
            const link = $(this).attr('data-url');
            if (link.length) {
                const xhr = new XMLHttpRequest();
                xhr.open('GET', '/ContactFormPage/DisplayForm/?url=' + link);
                xhr.onload = function (e) {
                    if (xhr.status === 200) {
                        const response = xhr.responseText;
                        if (response !== null && response !== undefined) {
                            $('body').append(response);
                            activateValidation();
                            return;
                        }
                    };
                }
                xhr.send();
            }
        });
        $(document).on('click', '.contact-form__close', function () {
            $('.contact-form-modal').remove();
        });
    }
}