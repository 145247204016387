﻿function Observer() {
    this.handlers = [];  // observers
}

Observer.prototype = {

    subscribe: function (name, fn) {
        //arguments.splice()
        if (!this.handlers[name])
            this.handlers[name] = [];

        this.handlers[name].push(fn);
    },

    unsubscribe: function (name, fn) {
        if (this.handlers[name] && this.handler[name].hasOwnProperty("filter")) {
            this.handlers[name] = this.handlers[name].filter(
                function (item) {
                    if (item !== fn) {
                        return item;
                    }
                }
            );
        }
        
    },

    fire: function (name) {
        console.log("fire!", name, this.handlers);
        if(this.handlers[name])
            this.handlers[name].forEach(function (item) { 
                try {
                    console.log("Fire subscribed event", name);
                    item.call();
                }
                catch(e) {

                } 
                
        }); 
    }
}


window.observer = new Observer();