const stepByStep = {
    stepByStep: '.steps-block',
    stepByStepNav: 'steps-block__nav',
    stepByStepNavItem: '.steps-block__navitem',
    stepByStepNavLink: '.steps-block__navlink',
    stepByStepNavActive: 'steps-block__navitem--active',
    stepByStepBlock: '.stepbystep-block',
    stepByStepBlockActive: 'stepbystep-block--active',
    init() {

        const $stepByStep = $(this.stepByStep);
        const stepByStep = this.stepByStep;
        const stepByStepNav = this.stepByStepNav;
        const stepByStepNavItem = this.stepByStepNavItem;
        const stepByStepNavLink = this.stepByStepNavLink;
        const stepByStepNavActive = this.stepByStepNavActive;
        const stepByStepBlock = this.stepByStepBlock;
        const stepByStepBlockActive = this.stepByStepBlockActive;

        $stepByStep.each(function () {
            const _this = $(this);

            _this.find(stepByStepBlock).first().addClass(stepByStepBlockActive);
            _this.find(stepByStepNavItem).first().addClass(stepByStepNavActive);

            _this.find(stepByStepNavLink).on("click", function (e) {
                e.preventDefault();

                $('html, body').animate({ scrollTop: _this.offset().top }, 400, function () { });

                _this.find('.' + stepByStepNavActive).removeClass(stepByStepNavActive);
                $(this).parent().addClass(stepByStepNavActive);

                _this.find('.' + stepByStepBlockActive).removeClass(stepByStepBlockActive);
                _this.find(stepByStepBlock).eq($(this).parent().index()).addClass(stepByStepBlockActive);
            });

        });
    }
};

export default stepByStep;
stepByStep.init();