const stepByStep = {
    stepByStep:             '.stepbystep',
    stepByStepNav:          'stepbystep__nav',
    stepByStepNavItem:      '.stepbystep__navitem',
    stepByStepNavLink:      '.stepbystep__navlink',
    stepByStepNavActive:    'stepbystep__navitem--active',
    stepByStepBlock:        '.stepbystep-block',
    stepByStepBlockActive:  'stepbystep-block--active',
    init() {

        const $stepByStep =             $(this.stepByStep);
        const stepByStepNav =           this.stepByStepNav;
        const $stepByStepNavItem =      $(this.stepByStepNavItem);
        const $stepByStepNavLink =      $(this.stepByStepNavLink);
        const stepByStepNavActive =     this.stepByStepNavActive;
        const $stepByStepBlock =        $(this.stepByStepBlock);
        const stepByStepBlockActive =   this.stepByStepBlockActive;
        
        $stepByStepBlock.first().addClass(stepByStepBlockActive);
        $stepByStepNavItem.first().addClass(stepByStepNavActive);

        $stepByStepNavLink.on( "click", function(e) {
            e.preventDefault();
            $('.'+stepByStepNavActive).removeClass(stepByStepNavActive);
            $(this).parent().addClass(stepByStepNavActive);
            $('.'+stepByStepBlockActive).removeClass(stepByStepBlockActive);
            $stepByStepBlock.eq($(this).parent().index()).addClass(stepByStepBlockActive);
        });

    }
};

export default stepByStep;
stepByStep.init();