import jQuery from 'jquery';
global.$ = global.jQuery = require('jquery');
import eService from '../../EService/Scripts/EService.js';

const readSpeaker = {
  readSpeaker:            '.readspeaker',
  readSpeakerBtn:         '.hygiennav__item--listen a',
  readspeakerClose:       '#rsplayer',
  readSpeakerOpen:        'readspeaker--open',
  readSpeakerSticky:      'readspeaker--sticky',
  init() {

    const $readSpeaker =            $(this.readSpeaker);
    const $readSpeakerBtn =         $(this.readSpeakerBtn);
    const $readspeakerClose =       $(this.readspeakerClose);
    const readSpeakerOffset =       $readSpeaker.offset().top;
    const readSpeakerSticky =       this.readSpeakerSticky;
    const readSpeakerOpen =         this.readSpeakerOpen;

    $readSpeakerBtn.on("click", function(e) {
      $readSpeaker.addClass(readSpeakerOpen);
    });

    $(window).scroll(function() {
      var windowTop = $(window).scrollTop();
      if (readSpeakerOffset < windowTop) {
        $readSpeaker.addClass(readSpeakerSticky);
      } else {
        $readSpeaker.removeClass(readSpeakerSticky);
      }
    });

  }
};

export default readSpeaker;
readSpeaker.init();