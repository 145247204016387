﻿const $nominationPageLink = $('.nomination-form-link');
const $nominationForm = $('.nomination-form');

if ($nominationPageLink.length || $nominationForm.length) {
    if (!String.prototype.includes) {
        Object.defineProperty(String.prototype, 'includes', {
            value: function (search, start) {
                if (typeof start !== 'number') {
                    start = 0
                }

                if (start + search.length > this.length) {
                    return false
                } else {
                    return this.indexOf(search, start) !== -1
                }
            }
        })
    }

    const activateValidation = function () {
        const form = '#Nomination';
        const nominatorFirstName = '#NominatorFirstName';
        const nominatorLastName = '#NominatorLastName';
        const nominatorEmail = "#NominatorEmail";
        const nominatorBy = '#NominationBy';
        const firstName = '#FirstName';
        const lastName = '#LastName';
        const email = '#Email';
        const toEmail = '#ToEmail';
        const phonenumber = '#Phonenumber';
        const address = '#Address';
        const message = '#Message';
        const typeOfNomination = '#TypeOfNominations';
        const accept = '#Accept';
        const send = '#NominationSend';
        const validClass = 'valid';
        const invalidClass = 'invalid';
        const typeOfNominationErrorText = 'Du måste välja minst ett uppdrag';
        const formData = $(form).serializeArray();
        const requiredFields =
            nominatorFirstName + ',' +
            nominatorLastName + ',' +
            nominatorEmail + ',' +
            nominatorBy + ',' +
            firstName + ',' +
            lastName + ',' +
            email + ',' +
            phonenumber + ',' +
            address;     
        const isValidEmail = function (value) {
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            const isEmail = re.test(value);
            return isEmail;
        }
        const isValidPhoneNumber = function (value) {
            if (!isNaN(value)) {
                return true;
            }
            else {
                return false;
            }
        }
        const displayErrorMessage = function (element) {
            const valid = $(element).hasClass(validClass);
            var errorElement = $(element).next('span');
            if ($(element).attr('id') === accept.replace('#', '')) {
                errorElement = $(element).next().next().next().next('span');
            }
            if (!valid) {
                var errorMessage;
                if ($(element).attr('id') === email.replace('#', '')) {
                    if ($(element).val()) {
                        errorMessage = $(element).attr('data-val-email');
                    }
                    else {
                        errorMessage = $(element).attr('data-val-required');
                    }
                }
                else if ($(element).attr('id') === nominatorEmail.replace('#', '')) {
                    if ($(element).val()) {                                          
                        errorMessage = $(element).attr('data-val-nominatorEmail');   
                    }                                                                
                    else {                                                           
                        errorMessage = $(element).attr('data-val-required');         
                    }                                                                
                }                                                                    
                else if ($(element).attr('id') === phonenumber.replace('#', '')) {
                    if ($(element).val()) {
                        errorMessage = $(element).attr('data-val-regex');
                    }
                    else {
                        errorMessage = $(element).attr('data-val-required');
                    }                 
                }
                else {
                    errorMessage = $(element).attr('data-val-required');
                }
                errorElement.empty();
                errorElement.text(errorMessage);
                errorElement.removeClass('field-validation-valid').addClass('field-validation-error');
            }
            else {
                errorElement.removeClass('field-validation-error').addClass('field-validation-valid');
                errorElement.empty();
            }
        }
        const displayTypeOfNominationValidation = function(valid) {
            const errorElement = $(typeOfNomination).next('span');
            errorElement.empty();
            if (valid) {
                errorElement.removeClass('field-validation-error');
                errorElement.addClass('field-validation-valid');
            }
            else {
                errorElement.removeClass('field-validation-valid');
                errorElement.addClass('field-validation-error');
                errorElement.text(typeOfNominationErrorText);
            }
        }
        const applyTypeOfNominationEvent = function (element) {
            var isValid = false;
            $(element).on('click', function () {
                for (var input in formData) {
                    if (formData[input]['name'].replace('.', '_').includes(typeOfNomination.replace('#', '')) && $(element).is(':checked')) {
                        isValid = true;
                    }
                }
                displayTypeOfNominationValidation(isValid);           
            });
        }
        for (var input in formData) {
            const element = $('#' + formData[input]['name'].replace('.', '_'));
            const invalid = element.hasClass('input-validation-error')
                || (element.val() === ''
                || (!element.is(':checked') && element.attr('id') === accept.replace('#', '')));
            if (invalid) {
                element.addClass(invalidClass);
            }
            else {
                element.addClass(validClass);
            }
            if (formData[input]['name'].replace('.', '_').includes(typeOfNomination.replace('#', ''))) {
                applyTypeOfNominationEvent(element);
            }
        }
        $(accept).click(function () {
            const input = $(this);
            if ($(this).is(':checked')) {
                input.removeClass(invalidClass).addClass(validClass);
                displayErrorMessage(this);
            }
            else {
                input.removeClass(validClass).addClass(invalidClass);
                displayErrorMessage(this);
            }
        });
        $(requiredFields).on('change input', function () {
            const input = $(this);
            const isName = input.val();
            if ($(input).attr('id') === email.replace('#', '') && !isValidEmail(input.val())) {
                input.removeClass(validClass).addClass(invalidClass);
            }
            else if ($(input).attr('id') === nominatorEmail.replace('#', '') && !isValidEmail(input.val())) {
                input.removeClass(validClass).addClass(invalidClass);
            }
            else if ($(input).attr('id') === phonenumber.replace('#', '') && !isValidPhoneNumber(input.val())) {
                input.removeClass(validClass).addClass(invalidClass);
            }
            else if (!isName) {
                input.removeClass(validClass).addClass(invalidClass);
            }
            else {
                input.removeClass(invalidClass).addClass(validClass);
            }
            displayErrorMessage(input);    
        });
        $(requiredFields).on('change', function () {
            var error_free = true;
            for (var input in formData) {
                const element = $('#' + formData[input]['name']);
                const valid = element.hasClass(validClass);
                if (!valid) {
                    error_free = false;
                }
            }
            if (error_free) {
                $(send).css('cursor', 'pointer');
            } else {
                $(send).css('cursor', 'auto');
            }
        });
        $(send).on('click', function (e) {
            e.preventDefault();
            var error_free = true;
            var hasCheckboxes = false;
            for (var input in formData) {
                const element =  $('#' + formData[input]['name'].replace('.','_'));
                if (formData[input]['name'].replace('.', '_').includes(typeOfNomination.replace('#', '')) && $(element).is(':checked')) {
                    hasCheckboxes = true;
                }
                else {
                    displayErrorMessage(element);
                }               
                const valid = element.hasClass(validClass) || formData[input]['name'].replace('.', '_').includes(typeOfNomination.replace('#', '')) || formData[input]['name'].includes('Message');
                if (!valid) {
                    error_free = false;
                }
            }
            if (!hasCheckboxes) {
                displayTypeOfNominationValidation(false);
            }
            else {
                displayTypeOfNominationValidation(true);
            }
            if (error_free && hasCheckboxes) {
                $('.loading-overlay').removeClass('hidden');
                const form = {
                    ToEmail: $(toEmail).val(),
                    NominatorFirstName: $(nominatorFirstName).val(),
                    NominatorLastName: $(nominatorLastName).val(),
                    NominatorEmail: $(nominatorEmail).val(),
                    NominationBy: $(nominatorBy).find(":selected").val(),
                    FirstName: $(firstName).val(),
                    LastName: $(lastName).val(),
                    Email: $(email).val(),
                    Phonenumber: $(phonenumber).val(),
                    Address: $(address).val(),
                    TypeOfNominations: {
                        Ledamot: $(typeOfNomination + '_Ledamot').is(':checked'), 
                        Suppleant: $(typeOfNomination + '_Suppleant').is(':checked'),
                        Revisor: $(typeOfNomination + '_Revisor').is(':checked'),
                        Revisorssuppleant: $(typeOfNomination + '_Revisorssuppleant').is(':checked'),
                        Motesordforande: $(typeOfNomination + '_Motesordforande').is(':checked'),
                        LedamotValberedning: $(typeOfNomination + '_LedamotValberedning').is(':checked'),
                        SuppleantValberedning: $(typeOfNomination + '_SuppleantValberedning').is(':checked')
                    },
                    Message: $(message).val()
                };
                const xhr = new XMLHttpRequest();
                xhr.open('POST', '/NominationFormPage/SendForm');
                xhr.setRequestHeader("Content-type", "application/json");
                xhr.onload = function (e) {
                    if (xhr.status === 200) {
                        const response = xhr.responseText;
                        if (response !== null && response !== undefined) {
                            if ($('.nomination-form-container').length) {
                                $('.nomination-form-container')[0].innerHTML = response;
                                $('html,body').scrollTop(0);
                            }                        
                            return;
                        }
                    };
                }
                xhr.send(JSON.stringify(form));
            }
        });
    };

    if($nominationForm.length) {
        activateValidation();
    }

    if ($nominationPageLink.length && $nominationPageLink.prop("tagName") !== 'button') {
        for (var i = 0; $nominationPageLink.length > i; i++) {
            const urlValue = $($nominationPageLink[i]).attr('href');
            const classes = $($nominationPageLink[i]).attr('class');
            $($nominationPageLink[i]).replaceWith($('<button data-url="' + urlValue + '" class="' + classes + '">' + $($nominationPageLink[i]).text() + '</button>'));
        }
        $('.nomination-form-link').on('click', function () {
            const link = $(this).attr('data-url');
            if (link.length) {
                const xhr = new XMLHttpRequest();
                xhr.open('GET', '/NominationFormPage/DisplayForm/?url=' + link);
                xhr.onload = function (e) {
                    if (xhr.status === 200) {
                        const response = xhr.responseText;
                        if (response !== null && response !== undefined) {
                            $('body').append(response);
                            activateValidation();
                            return;
                        }
                    };
                }
                xhr.send();
            }
        });
        $(document).on('click', '.nomination-form__close', function () {
            $('.nomination-form-modal').remove();
        });
    }
}