import jQuery from 'jquery';
global.$ = global.jQuery = require('jquery');
import mobileNav from '../../MobileNavigation/Scripts/MobileNavigation.js';
import mainNav from '../../MainNavigation/Scripts/MainNavigation.js';

const eService = {
  eservice:                 '.eservice',
  eserviceBtn:              '.hygiennav__item--eservice',
  eserviceHeading:          '.eservice__heading',
  eserviceClose:            '.eservice__close',
  mainNavCont:              '.header__mainnav',
  mainNav:                  '.mainnav',
  mobileNavCont:            '.header__mobilenav',
  keypress:                 false,
  init() {

    const $eservice         = $(this.eservice);
    const $eserviceBtn      = $(this.eserviceBtn);
    const $eserviceHeading  = $(this.eserviceHeading);
    const $eserviceClose    = $(this.eserviceClose);
    const $mainNavCont      = $(this.mainNavCont);
    const $mainNav          = $(this.mainNav);
    const $mobileNavCont    = $(this.mobileNavCont);
    const _this             = this;
    var keypress            = this.keypress;

    $eserviceBtn.on('keydown',function(evt){
      if (evt.which == 13) {
        keypress = true;
      }
    });

    $eserviceBtn.on("click", function(e) {
      e.preventDefault();

      if( $eservice.attr('aria-hidden') == "true") {
        _this.openEService(keypress);
      }else {
        _this.closeEService();
      }

      if($mainNavCont.hasClass('open')){
        mainNav.closeMainNav();
      }

      if($mobileNavCont.hasClass('open')){
        mobileNav.closeMobileNav();
      }

    });
    $eserviceClose.on("click", function(e) {
      e.preventDefault();
      $eserviceBtn.trigger('click');
    });
    
    $eservice.on('keydown',function(evt){    // if tab or shift-tab pressed
      if (evt.which == 9) {
        _this.trapTabKey($(this), evt);
      }else if(evt.which == 27){
        _this.trapEscapeKey($(this), evt);
      }
    });

  },
  openEService(keypress) {

    $('body').addClass('eservice-open');
    $(this.eserviceBtn).addClass('open');
    $(this.eservice).addClass('open');
    $(this.eservice).attr('aria-hidden', 'false');
    $(this.eservice).find('a').attr('tabindex', '0');
    $(this.eserviceHeading).attr('tabindex', '0');
    $(this.eserviceClose).attr('tabindex', '0');
    if(keypress){
      keypress = false;
      $(this.eserviceClose).focus();
    }else {
      setTimeout(function(){
        $(this.eserviceHeading).focus();
      }, 500);
    }
    
    var $eserviceBtn = $(this.eserviceBtn);
    $('html').on("click.eserviceoutsideclick", $('eservice-open'), function(e) {
      var container = $('.header-container');
      
      if($(e.target).closest(container).length != 1){
        
        $eserviceBtn.trigger('click');
      }
    });

  },
  closeEService() {
    
    $('html').off(".eserviceoutsideclick");
    $('body').removeClass('eservice-open');
    $(this.eserviceBtn).removeClass('open');
    $(this.eservice).removeClass('open');
    $(this.eserviceBtn).find('.hygiennav__link').focus();
    $(this.eservice).find('a').attr('tabindex', '-1');
    $(this.eserviceHeading).attr('tabindex', '-1');
    $(this.eserviceClose).attr('tabindex', '-1');
    $(this.eservice).attr('aria-hidden', 'true');
    
  },
  trapTabKey(obj, evt) {

    var focusableElementsString = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]';

        // get list of all children elements in given object
        var o = obj.find('*');

        // get list of focusable items
        var focusableItems;
        focusableItems = o.filter(focusableElementsString).filter(':visible')

        // get currently focused item
        var focusedItem;
        focusedItem = jQuery(':focus');

        // get the number of focusable items
        var numberOfFocusableItems;
        numberOfFocusableItems = focusableItems.length

        // get the index of the currently focused item
        var focusedItemIndex;
        focusedItemIndex = focusableItems.index(focusedItem);

        if (evt.shiftKey) {
            //back tab
            // if focused on first item and user preses back-tab, go to the last focusable item
            if (focusedItemIndex == 0) {
                focusableItems.get(numberOfFocusableItems - 1).focus();
                evt.preventDefault();
            }

        } else {
            //forward tab
            // if focused on the last item and user preses tab, go to the first focusable item
            if (focusedItemIndex == numberOfFocusableItems - 1) {
                focusableItems.get(0).focus();
                evt.preventDefault();
            }
        }
  },
  trapEscapeKey(obj, evt) {
    
    // if escape pressed

      // get list of all children elements in given object
      var o = obj.find('*');

      // get list of focusable items
      var cancelElement;
      cancelElement = o.filter(this.eserviceClose);
      // close the modal window
      cancelElement.trigger('click');
      evt.preventDefault();

  }
};

export default eService;
eService.init();