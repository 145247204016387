import jQuery from 'jquery';
global.$ = global.jQuery = require('jquery');

const sideNavigation = {
  sideNavItem:          '.sidenav__item ',
  sideNavSecondLvl:     '.sidenav__secondlvl',
  sideNavSecondLvlBtn:  '.sidenav__secondlvlbtn',
  sideNavThirdLvlBtn:   '.sidenav__thirdlvlbtn ',
  sideNavThirdLvl:      '.sidenav__third-lvl ',
  sideNavSecondLvlOpen: 'sidenav--secondlvlopen',
  sideNavThirdLvlOpen:  'sidenav--thirdlvlopen',
  init() {

    const $sideNavItem =          $(this.sideNavItem);
    const $sideNavSecondLvlBtn =  $(this.sideNavSecondLvlBtn);
    const $sideNavThirdLvlBtn =   $(this.sideNavThirdLvlBtn);
    const sideNavSecondLvl =      this.sideNavSecondLvl;
    const sideNavThirdLvl =       this.sideNavThirdLvl;
    const sideNavSecondLvlOpen =  this.sideNavSecondLvlOpen;
    const sideNavThirdLvlOpen =   this.sideNavThirdLvlOpen;

    $sideNavSecondLvlBtn.on("touchend click", function(e) {
      e.preventDefault();
      if($(this).closest($sideNavItem).hasClass(sideNavSecondLvlOpen)){
        $(this).closest($sideNavItem).removeClass(sideNavSecondLvlOpen);
        $(this).next().find('> li > a').attr('tabindex', '-1');
        $(this).closest($sideNavItem).find('.sidenav__thirdlvlbtn').attr('tabindex', '-1');
        $(this).closest($sideNavItem).next('button').focus();
      }else {
        $($sideNavItem).removeClass(sideNavSecondLvlOpen);
        $(this).closest($sideNavItem).toggleClass(sideNavSecondLvlOpen);
        $(this).next().find('> li > a').attr('tabindex', '0');
        $(this).closest($sideNavItem).find('.sidenav__thirdlvlbtn').attr('tabindex', '0');
        $(this).closest($sideNavItem).find('ul a')[0].focus();
      }
    });

    $sideNavThirdLvlBtn.on("touchend click", function(e) {
      e.preventDefault();
      if($(this).closest($sideNavItem).hasClass(sideNavThirdLvlOpen)){
        $(this).closest($sideNavItem).removeClass(sideNavThirdLvlOpen);
        $(this).next().find('> li > a').attr('tabindex', '-1');
        $(this).closest($sideNavItem).next('button').focus();
      }else {
        $($sideNavItem).removeClass(sideNavThirdLvlOpen);
        $(this).closest($sideNavItem).toggleClass(sideNavThirdLvlOpen);
        $(this).next().find('> li > a').attr('tabindex', '0');
        $(this).closest($sideNavItem).find('ul a')[0].focus();
      }
    });

  }
};

export default sideNavigation;
sideNavigation.init();