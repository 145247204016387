const EmergencyNotification = {
    emergencyNotification:          '.emergency-notification',
    emergencyNotificationBtn:       '.emergency-notification__btn',
    emergencyNotificationClosed:    'emergency-notification__closed',
    emergencyNotificationVisible:   'emergency-notification__visible',
    init() {

        const $emergencyNotification       = $(this.emergencyNotification);
        const $emergencyNotificationBtn    = $(this.emergencyNotificationBtn);
        const emergencyNotificationClosed  = this.emergencyNotificationClosed;
        const emergencyNotificationVisible  = this.emergencyNotificationVisible;


        /*$emergencyNotificationBtn.on("click", function(e) {
            if($emergencyNotification.hasClass(emergencyNotificationClosed) ) {
                $emergencyNotification.removeClass(emergencyNotificationClosed)
            }else {
                $emergencyNotification.addClass(emergencyNotificationClosed)
            }
        });*/
        $emergencyNotification.each(function( index ) {
            if(sessionStorage.getItem('emergencynotification'+index) != "true") {
                $(this).addClass(emergencyNotificationVisible);
            }
        });

        $emergencyNotificationBtn.on("click", function(e) {
            $(this).parent().parent().removeClass(emergencyNotificationVisible);
            sessionStorage.setItem('emergencynotification'+$(this).parent().parent().index(), "true");
        });
      
    }
};

export default EmergencyNotification;
EmergencyNotification.init();