import jQuery from 'jquery';
global.$ = global.jQuery = require('jquery');

const searchFunc = {
  searchBtn:  '.search__btn',
  init() {

    const is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const $searchBtn =  $(this.searchBtn);
    
    $searchBtn.on('click',function(evt){
      if(is_safari){
        $(this).parent().addClass('search--loadingsafari');
      }else {
        $(this).parent().addClass('search--loading');
      }
    });

  }
};

export default searchFunc;
searchFunc.init();