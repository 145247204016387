﻿const faqArchive = {
    faqNavMobileSelect:     '.faq-archive__navmobileselect',
    faqNavMobileIcon:       '.faq-archive__navmobileicon',
    faqNavItem:             '.faq-archive__navitem',
    faqCategoryBlock:       '.faq-category-block',
    faqCategoryBlockItem:   '.faq-category-block__categoriesitem',
    faqBlock:               '.faq-block',
    faqBlockQuestion:       '.faq-block__question',
    faqBlockAnswer:         '.faq-block__answer',
    faqNavItemOpen:         'faq-archive__navitem--open',
    faqCategoryBlockOpen:   'faq-category-block--open',
    faqBlockActive:         'faq-block--active',
    faqBlockQuestionActive: 'faq-block__question--active',
    faqBlockAnswerActive:   'faq-block__answer--active',
    init() {

        const $faqNavMobileSelect   = $(this.faqNavMobileSelect);
        const $faqNavMobileIcon     = $(this.faqNavMobileIcon);
        const $faqNavItem           = $(this.faqNavItem);
        const $faqCategoryBlock     = $(this.faqCategoryBlock);
        const faqNavItemOpen        = this.faqNavItemOpen;
        const faqCategoryBlockItem  = this.faqCategoryBlockItem;
        const faqCategoryBlockOpen  = this.faqCategoryBlockOpen;
        const $faqBlockQuestion         = $(this.faqBlockQuestion);
        const $faqBlockAnswer           = $(this.faqBlockAnswer);
        const faqBlockQuestionActive    = this.faqBlockQuestionActive;
        const faqBlockAnswerActive      = this.faqBlockAnswerActive;
        const updateQuery               = function updateQueryStringParameter(uri, key, value) {
            var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
            var separator = uri.indexOf('?') !== -1 ? "&" : "?";
            if (uri.match(re)) {
                return uri.replace(re, '$1' + key + "=" + value + '$2');
            }
            else {
                return uri + separator + key + "=" + value;
            }
        }
        $faqNavMobileSelect.on("change", function(e) {
            $faqNavMobileIcon.html($(this).find('option:selected').data('svg'))
            $faqNavItem.eq($(this).find('option:selected').index()).trigger('click');
        });
        $faqNavMobileIcon.html($faqNavMobileSelect.find('option').eq(0).data('svg'));

        $faqNavItem.on("click", function (event, firstload) {
            $faqNavItem.removeClass(faqNavItemOpen);
            $faqCategoryBlock.removeClass(faqCategoryBlockOpen);
            $faqBlockQuestion.removeClass(faqBlockQuestionActive);
            $faqBlockAnswer.removeClass(faqBlockAnswerActive);

            $(this).addClass(faqNavItemOpen);
            $faqCategoryBlock.eq($(this).index()).addClass(faqCategoryBlockOpen);
            $faqCategoryBlock.eq($(this).index()).find(faqCategoryBlockItem).eq(0).trigger('click');
            $faqNavMobileSelect.find('option').eq($(this).index()).attr('selected', 'selected');

            let newSectionId = document.getElementsByClassName('faq-archive__navitem--open');
            if (newSectionId.length > 0 && firstload !== 'true') {
                var uri = window.location.href.split('?')[0];
                uri = updateQuery(uri, 'sectionId', newSectionId[0].getElementsByTagName('button')[0].dataset.id);
                if (window.history.pushState) {
                    window.history.pushState({ path: uri }, '', uri);
                }
            }
        });
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });
        let value = params.sectionId;
        let firstItem = document.querySelectorAll('[data-id="' + value + '"]');
        if (firstItem.length > 0) {
            let firstOpen = $(firstItem[0]);
            firstOpen.trigger('click', ['true']);
        } else {
            $faqNavItem.eq(0).trigger('click', ['true']);
        }
    }
};

export default faqArchive;
faqArchive.init();  